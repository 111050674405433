<template>
  <div>
    <div class="toolbar" v-if="false">
      <button @click="save">Save</button>
      <button @click="load">Load</button>
      <button disabled @click="mode = mode === 'add' ? '' : 'add'">
        <template v-if="mode === 'add'">Adding</template>
        <template v-else>Add</template>
      </button>
      <select v-model="type">
        <option value="rect">Square</option>
        <option value="circle">Circle</option>
        <option value="custom">Custom</option>
      </select>
      <input type="color" v-model="color" />

      <button @click="mode = mode === 'edit' ? '' : 'edit'">
        <template v-if="mode === 'edit'">Editing</template>
        <template v-else>Edit</template>
      </button>
      <button @click="mode = mode === 'select' ? '' : 'select'">
        <template v-if="mode === 'select'">Selecting</template>
        <template v-else>Select</template>
      </button>
      <button @click="mode = mode === 'delete' ? '' : 'delete'">
        <template v-if="mode === 'delete'">Deleting</template>
        <template v-else>Delete</template>
      </button>

      <input type="file" accept="image" @change="uploadImage" />
    </div>
    <div class="row">
      <div class="col-lg-10">
        <p class="ml-2">
          Operacia: {{ this.mode ? this.mode : 'Przegląd' }}, {{ shipRegistration ? 'Rejestracia statku ' + shipRegistration.numberStr : '' }},
          {{ deliveryOrder ? 'Zlecenie ' + deliveryOrder.numberStr : '' }}
        </p>
      </div>
      <div class="col-lg-2" align="right">
        <b-button variant="outline-secondary" class="mr-1" size="sm" @click="() => handleZoom(1)"><i class="ri-zoom-in-line"></i></b-button>
        <b-button variant="outline-secondary" class="mr-1" size="sm" @click="() => handleZoom(-1)"><i class="ri-zoom-out-line"></i></b-button>
        <b-button variant="outline-secondary" class="mr-2" size="sm" @click="() => handleZoom(0)"><i class="ri-arrow-go-back-fill"></i></b-button>
      </div>
    </div>

    <div class="container">
      <textarea ref="titleInput" class="title-input" v-model="titleValue"></textarea>
      <v-stage
        ref="stage"
        :config="{ ...configKonva, scaleX: stageScale, scaleY: stageScale }"
        @mousedown="handleStageMouseDown"
        @mousemove="handleStageMouseMove"
        @mouseup="handleStageMouseup"
        @click="handleStageClick"
        @dblclick="handleStageDoubleClick"
      >
        <!-- Background Map Layer -->
        <v-layer>
          <v-rect
            :config="{
              x: 0,
              y: 0,
              width: configKonva.width / stageScale,
              height: configKonva.height / stageScale,
              fillPatternImage: mapImage,
            }"
          />
        </v-layer>

        <!--  Renderer Layer -->
        <v-layer>
          <v-group v-for="item in data" :key="item.name" :config="{ draggable: mode === 'edit' }" @dragmove="handleCustomShapeDragMove" @dragend="stopDrag" @contextmenu="openContextMenu($event, item)">
            <v-rect v-if="item.type === 'rect'" :config="{ ...item.shape, id: null, stroke: item.shape.selected ? 'red' : 'transparent', strokeWidth: item.shape.selected ? 4 : 0 }" @transformend="handleTransformEnd" />
            <v-circle v-if="item.type === 'circle'" :config="{ ...item.shape, id: null, stroke: item.shape.selected ? 'red' : 'transparent', strokeWidth: item.shape.selected ? 4 : 0 }" @transformend="handleTransformEnd" />
            <v-line v-if="item.type === 'custom'" :config="{ ...item.shape, id: null, stroke: item.shape.selected ? 'red' : 'transparent', strokeWidth: item.shape.selected ? 4 : 0 }" />
            <v-text :config="item.title" />
          </v-group>
        </v-layer>

        <!--  Add/Edit Layer -->
        <v-layer ref="formLayer">
          <v-group>
            <v-rect v-if="formItem.type === 'rect'" :config="{ ...formItem.shape, id: null }" />
            <v-circle v-if="formItem.type === 'circle'" :config="{ ...formItem.shape, id: null }" />
            <v-line v-if="formItem.type === 'custom'" :config="{ ...formItem.shape, id: null }" />
            <v-text :config="formItem.title" />
          </v-group>
          <v-transformer ref="transformer" />
        </v-layer>
      </v-stage>

      <div
        v-if="contextMenu.visible"
        class="contextmenu"
        :style="{ top: `${contextMenu.top}px`, left: `${contextMenu.left}px` }"
      >
        <ul class="contextmenu">
          <li v-for="item in contextMenuItems" :key="item.text" @click="item.click">
            <i :class="item.icon + ' mr-1'"></i>{{ item.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Konva from 'konva'
import { showMessageBox } from '@/utils/commonUse.js'
import { onBeforeUpdate } from 'vue'
import moment from 'moment'
import _ from 'lodash'
import { rgbToHex as commoUseRgbToHex } from '@/utils/commonUse.js'

export default {
  components: {},

  data() {
    return {
      name: 'drawMap',
      // mode: '',
      // type: 'rect',
      // color: '#5FA9C8',
      mapImage: null,
      configKonva: {
        width: window.innerWidth - 64,
        height: window.innerHeight - 140,
      },
      defaultSize: {
        width: window.innerWidth - 64,
        height: window.innerHeight - 140,
      },
      stageScale: 1,
      formItem: {
        type: '',
        shape: {},
        title: {},
      },
      isDrawing: false,
      selectedCargoTitle: '',
      selectedTitleNode: null,
      titleValue: '',
      selectedCargoName: '',
      anchors: [],
      canvasBoxesLocation: null,
      currentOperation: '',
      contextMenu: {
        visible: false,
        left: 0,
        top: 0,
      },
      contextMenuItems: [
        { icon: 'ri-file-list-3-line', text: this.$t('route.wmsTurnover'), click: this.openWmsTurnover, visible: true },
      ],
    }
  },

  computed: {
    ...mapGetters({
      currentView: 'canvas/currentView',
    }),
    isAllBoxes: {
      get() {
        return this.currentView.isAllBoxes
      },
      set(value) {
        this.setCurrentViewProperty({ isAllBoxes: value })
      },
    },
    selectedBoxFromList: {
      get() {
        return this.currentView.selectedBoxFromList
      },
      set(value) {
        this.setCurrentViewProperty({ selectedBoxFromList: value })
      },
    },
    shipRegistration: {
      get() {
        return this.currentView.shipRegistration
      },
      set(value) {
        this.setCurrentViewProperty({ shipRegistration: value })
      },
    },
    deliveryOrder: {
      get() {
        return this.currentView.deliveryOrder
      },
      set(value) {
        this.setCurrentViewProperty({ deliveryOrder: value })
      },
    },
    deliveryOrdersArray: {
      get() {
        return this.currentView.deliveryOrdersArray
      },
      set(value) {
        this.setCurrentViewProperty({ deliveryOrdersArray: value })
      },
    },
    product: {
      get() {
        return this.currentView.product
      },
      set(value) {
        this.setCurrentViewProperty({ product: value })
      },
    },
    mapSrc: {
      get() {
        return this.currentView.mapSrc
      },
      set(value) {
        this.setCurrentViewProperty({ mapSrc: value })
      },
    },
    data: {
      get() {
        return this.currentView.data
      },
      set(value) {
        this.setCurrentViewProperty({ data: value })
      },
    },
    mode: {
      get() {
        return this.currentView.mode
      },
      set(value) {
        this.setCurrentViewProperty({ mode: value })
      },
    },
    type: {
      get() {
        return this.currentView.type
      },
      set(value) {
        this.setCurrentViewProperty({ type: value })
      },
    },
    color: {
      get() {
        return this.currentView.color
      },
      set(value) {
        this.setCurrentViewProperty({ color: value })
        console.log('color value', value)
      },
    },
    defaultColor: {
      get() {
        return this.currentView.defaultColor
      },
    },
    shapeTypes: {
      get() {
        return this.currentView.shapeTypes
      },
    },
    selectedMapFile: {
      get() {
        return this.currentView.selectedMapFile
      },
      set(value) {
        this.setCurrentViewProperty({ selectedMapFile: value })
      },
    },
    date: {
      get() {
        return this.currentView.date
      },
      set(value) {
        this.setCurrentViewProperty({ date: value })
      },
    },
    parentViewId: {
      get() {
        return this.currentView.parentViewId
      },
      set(value) {
        this.setCurrentViewProperty({ parentViewId: value })
      },
    },
    selectedCell: {
      get() {
        return this.currentView.selectedCell
      },
      set(value) {
        this.setCurrentViewProperty({ selectedCell: value })
      },
    },
    selectedBox: {
      get() {
        return this.currentView.selectedBox
      },
      set(value) {
        this.setCurrentViewProperty({ selectedBox: value })
      },
    },
  },

  watch: {
    mode(newVal, oldVal) {
      this.polyTransformerDetach()

      if (oldVal === 'edit') {
        this.selectedCargoName = ''
        const transformerNode = this.$refs.transformer.getNode()
        transformerNode.nodes([])
        return
      }

      if (oldVal === 'add') {
        this.isDrawing = false
      }
    },
    color(newVal) {
      if (this.mode === 'edit' && this.selectedCargoName) {
        const item = this.data.find((r) => r.shape.name === this.selectedCargoName)
        item.shape.fill = newVal
      }
    },
    selectedMapFile(newVal) {
      if (newVal) {
        this.uploadImageContinue()
      } else {
        this.loadMapFromSettings()
      }
    },
    isAllBoxes() {
      this.data = []
      this.load()
    },
    selectedBoxFromList() {
      this.load()
    },
    date() {
      this.data = []
      if (!this.date) {
        return
      }
      this.load()
    },
    shipRegistration(newVal) {
      if (this.mode === 'selectBox') {
        if (newVal || this.deliveryOrder) {
          this.isAllBoxes = false
        } else {
          this.isAllBoxes = true
        }
        this.data = []
        this.load()
      }
    },
    deliveryOrder(newVal) {
      if (this.mode === 'selectBox') {
        if (newVal || this.shipRegistration) {
          this.isAllBoxes = false
        } else {
          this.isAllBoxes = true
        }
        this.data = []
        this.load()
      }
    },
    'contextMenu.visible'(newValue, oldValue) {
      if (newValue) {
        document.body.addEventListener('click', this.closeContextMenu)
      } else {
        document.body.removeEventListener('click', this.closeContextMenu)
      }
    },
  },

  async mounted() {
    if (!this.mapSrc) {
      await this.loadMapFromSettings()
    } else {
      this.updateMap()
    }
    this.load()
  },
  methods: {
    ...mapMutations({
      setCurrentViewProperty: `canvas/setCurrentViewProperty`,
    }),

    async load_old() {
      const dataArray = []
      this.data = []
      const params = {
        params: {
          filter: {},
          // attributes: ['boxId'],
        },
      }

      if (this.shipRegistration) {
        params.params.filter.shipRegistrationId = this.shipRegistration.id
      }
      if (this.isAllBoxes) {
        params.params.filter = {}
      }
      const response = await this.$store.dispatch('boxesPlacement/findAll', params)
      if (!response) {
        return
      }

      let i = 0
      const currentDate = new Date().toUTCString()
      for (const responseDataItem of response.data) {
        const dataItemExist = dataArray.find((item) => item.attributes.boxId === responseDataItem.boxId)
        if (dataItemExist) {
          if (dataItemExist.attributes.shipRegistrationId !== this.shipRegistration.id && responseDataItem.shipRegistrationId === this.shipRegistration.id) {
            dataItemExist.attributes.shipRegistrationId = responseDataItem.shipRegistrationId
          }
        } else {
          if (responseDataItem.shipRegistrationId !== this.shipRegistration.id && responseDataItem.boxId) {
            const responseBox = await this.$store.dispatch('boxes/findByPk', { noCommit: true, params: { id: responseDataItem.boxId } })
            if (responseBox && responseBox.status === 200) {
              if (responseBox.data.periodEnd && responseBox.data.periodEnd < currentDate) {
                continue
              }
            }
          }
          i++
          responseDataItem.canvas.shape.id = i
          responseDataItem.canvas.attributes = {}
          responseDataItem.canvas.attributes.shipRegistrationId = responseDataItem.shipRegistrationId
          responseDataItem.canvas.attributes.boxId = responseDataItem.boxId
          responseDataItem.canvas.text = responseDataItem.box.name
          dataArray.push(responseDataItem.canvas)
        }
      }

      for (const dataItem of dataArray) {
        if (this.shipRegistration && dataItem.attributes.shipRegistrationId === this.shipRegistration.id) {
          dataItem.shape.fill = this.color
        } else {
          dataItem.shape.fill = '#FF0000'
        }
      }

      this.setCurrentViewProperty({ data: dataArray })

      // test
      // for (const dataItem of response.data) {
      //   dataItem.canvas.title.text += '\n+ test'
      // }
    },

    async load() {
      const dataArray = []
      this.data = []
      const params = {
        params: {
          filter: {
            date: this.date,
          },
          // attributes: ['boxId'],
        },
      }

      if (!this.isAllBoxes) {
        if (this.shipRegistration) {
          params.params.filter.shipRegistrationId = this.shipRegistration.id
        }
        if (this.mode === 'selectBox' && this.deliveryOrder) {
          params.params.filter.deliveryOrderId = this.deliveryOrder.id
        }
      }
      const response = await this.$store.dispatch('boxes/getCanvasData', params)
      if (!response) {
        return
      }

      let i = 0
      const periodStr = this.date.toUTCString()
      for (const responseDataItem of response.data) {
        const dataItemExist = dataArray.find((item) => item.attributes.boxId === responseDataItem.boxId)
        if (dataItemExist) {
          if (
            this.shipRegistration &&
            dataItemExist.attributes.shipRegistrationId !== this.shipRegistration.id &&
            responseDataItem.shipRegistrationId === this.shipRegistration.id
          ) {
            dataItemExist.attributes.shipRegistrationId = responseDataItem.shipRegistrationId
          }
          if (!Object.hasOwn(dataItemExist.attributes, 'deliveryOrdersArray')) {
            dataItemExist.attributes.deliveryOrdersArray = []
            dataItemExist.attributes.deliveryOrdersArray.push(responseDataItem.deliveryOrderId)
          } else {
            const findIndex = dataItemExist.attributes.deliveryOrdersArray.indexOf(responseDataItem.deliveryOrderId)
            if (findIndex === -1) {
              dataItemExist.attributes.deliveryOrdersArray.push(responseDataItem.deliveryOrderId)
            }
          }
        } else {
          if (this.shipRegistration && responseDataItem.shipRegistrationId !== this.shipRegistration.id) {
            if (responseDataItem.periodEnd && responseDataItem.periodEnd < periodStr) {
              continue
            }
          }
          i++
          const currentCanvas = JSON.parse(responseDataItem.canvas)
          currentCanvas.shape.id = i
          currentCanvas.shape.name = 'cargo_' + i

          if (this.selectedBoxFromList === responseDataItem.boxId) {
            currentCanvas.shape.stroke = 'red'
            currentCanvas.shape.strokeWidth = 4
            currentCanvas.shape.selected = true
          }else{
            currentCanvas.shape.selected = false
          }

          if (responseDataItem.productColor) {
            currentCanvas.shape.fill = this.convertColorProduct(responseDataItem.productColor)
          } else {
            currentCanvas.shape.fill = this.defaultColor
          }
          currentCanvas.attributes = {}
          currentCanvas.attributes.shipRegistrationId = responseDataItem.shipRegistrationId
          currentCanvas.attributes.boxId = responseDataItem.boxId
          currentCanvas.attributes.deliveryOrdersArray = []
          currentCanvas.attributes.deliveryOrdersArray.push(responseDataItem.deliveryOrderId)
          currentCanvas.text = responseDataItem.boxName
          dataArray.push(currentCanvas)
        }
      }

      this.setCurrentViewProperty({ data: dataArray })
    },

    async save_old() {
      for (const item of this.data) {
        // if (item.attributes.shipRegistrationId !== this.shipRegistration.id) {
        //   continue
        // }
        // if (!Object.hasOwn(item.attributes, 'deliveryOrdersArray')) {
        //   continue
        // }
        // if (item.attributes.deliveryOrdersArray.length === 0) {
        //   continue
        // }
        if (Object.hasOwn(item, 'attributes') && Object.hasOwn(item.attributes, 'isModified') && item.attributes.isModified === true) {
          const boxData = {
            name: item.title.text.substring(0, 25),
          }
          let action = ''
          if (!item.attributes.boxId) {
            action = 'create'
          } else {
            const responseBox = await this.$store.dispatch('boxes/findByPk', { noCommit: true, params: { id: item.attributes.boxId } })
            if (responseBox && responseBox.status === 200) {
              action = 'update'
            } else {
              action = 'create'
            }
          }
          if (action === 'create') {
            boxData.periodStart = new Date()
          } else {
            boxData.id = item.attributes.boxId
            boxData.updatedAt = new Date()
          }
          const responseBox2 = await this.$store.dispatch('boxes/' + action, boxData)
          item.attributes.boxId = responseBox2.data.id

          const canvasToWrite = _.cloneDeep(item)
          delete canvasToWrite.attributes

          const params1 = {
            params: {
              filter: {
                boxId: item.attributes.boxId,
              },
            },
            noCommit: true,
          }
          const responseBoxPlacement1 = await this.$store.dispatch('boxesPlacement/findAll', params1)
          if (responseBoxPlacement1 && responseBoxPlacement1.status === 200) {
            for (const item1 of responseBoxPlacement1.data) {
              item1.updatedAt = new Date()
              item1.canvas = canvasToWrite
              await this.$store.dispatch('boxesPlacement/update', item1)
            }
          }

          action = ''
          const params = {
            params: {
              filter: {
                boxId: item.attributes.boxId,
                shipRegistrationId: this.shipRegistration.id,
              },
            },
            noCommit: true,
          }
          if (Object.hasOwn(item.attributes, 'deliveryOrdersArray')) {
            for (const itemArray of item.attributes.deliveryOrdersArray) {
              params.deliveryOrderId = itemArray
              const responseBoxPlacement2 = await this.$store.dispatch('boxesPlacement/findAll', params)
              if (responseBoxPlacement2 && responseBoxPlacement2.status === 200 && responseBoxPlacement2.data.length === 0) {
                const boxReplacementData = {
                  boxId: item.attributes.boxId,
                  deliveryOrderId: itemArray,
                  shipRegistrationId: this.shipRegistration.id,
                  canvas: canvasToWrite,
                }
                await this.$store.dispatch('boxesPlacement/create', boxReplacementData)
              }
            }
          }
        }
      }
      showMessageBox(this, 'Zrobione.', 28, false)
    },

    async saveItem(item) {
      const boxData = {
        name: item.title.text.substring(0, 25),
      }
      let action = ''
      if (!item.attributes.boxId) {
        action = 'create'
      } else {
        const responseBoxes = await this.$store.dispatch('boxes/findByPk', { noCommit: true, params: { id: item.attributes.boxId } })
        if (responseBoxes && responseBoxes.status === 200) {
          action = 'update'
        } else {
          action = 'create'
        }
      }
      if (action === 'create') {
        boxData.periodStart = new Date()
      } else {
        boxData.id = item.attributes.boxId
        boxData.updatedAt = new Date()
      }
      const responseBoxes2 = await this.$store.dispatch('boxes/' + action, boxData)
      item.attributes.boxId = responseBoxes2.data.id

      const canvasToWrite = _.cloneDeep(item)
      delete canvasToWrite.attributes
      const dateStr = moment(this.date).endOf('day').toISOString()
      const params1 = {
        params: {
          filter: {
            boxId: item.attributes.boxId,
            date: dateStr,
          },
        },
        noCommit: true,
      }
      action = 'create'
      const responseBoxesPlacement = await this.$store.dispatch('boxesPlacement/findAll', params1)
      if (responseBoxesPlacement && responseBoxesPlacement.status === 200) {
        for (const item of responseBoxesPlacement.data) {
          action = 'update'
          item.updatedAt = new Date()
          item.canvas = canvasToWrite
          await this.$store.dispatch('boxesPlacement/update', item)
        }
      }
      if (action === 'create') {
        const newBoxesPlacement = {
          boxId: item.attributes.boxId,
          date: dateStr,
          canvas: canvasToWrite,
        }
        await this.$store.dispatch('boxesPlacement/create', newBoxesPlacement)
      }

      if (item.attributes.deliveryOrderId) {
        const params = {
          params: {
            filter: {
              boxId: item.attributes.boxId,
              shipRegistrationId: this.shipRegistration?.id || null,
              deliveryOrderId: item.attributes.deliveryOrderId,
            },
          },
          noCommit: true,
        }
        const responseBoxesDocuments = await this.$store.dispatch('boxesDocuments/findAll', params)
        if (responseBoxesDocuments && responseBoxesDocuments.status === 200 && responseBoxesDocuments.data.length === 0) {
          const boxesDocumentData = {
            boxId: item.attributes.boxId,
            deliveryOrderId: item.attributes.deliveryOrderId,
            shipRegistrationId: this.shipRegistration?.id || null,
            productId: item.attributes.productId,
          }
          await this.$store.dispatch('boxesDocuments/create', boxesDocumentData)
        }
        item.attributes.deliveryOrderId = null
      }
      this.mode = ''
    },

    async loadMapFromSettings() {
      const response = await this.$store.dispatch('sync1CData/canvasBoxesPlacement')
      if (response && response.status === 200) {
        this.mapSrc = 'data:image/png;base64,' + response.data
      }
      this.updateMap()
    },

    uploadImage(e) {
      this.selectedMapFile = e.target.files[0]
    },

    async openWmsTurnover(){
      const box = await this.$store.dispatch('boxes/findByPk', { noCommit: true, params: { id: this.selectedBoxFromList } }).then((response) => {
        if(response.data && response.status === 200){
          return response.data
        }
        return null
      })
      this.$router.push({ name: 'wmsTurnover-report', query: { filter: { box: { value: box, operator: "=" }}}})
    },

    uploadImageContinue() {
      const vm = this
      const reader = new FileReader()
      try {
        const file = this.selectedMapFile
        if (file && file instanceof Blob) {
          reader.readAsDataURL(file)
          reader.onload = (ev) => {
            try {
              vm.mapSrc = ev.target.result
              vm.updateMap()
            } catch (err) {
              console.log(err)
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    updateMap() {
      const vm = this
      const imageObj = new Image()
      imageObj.src = this.mapSrc
      imageObj.onload = () => {
        try {
          vm.configKonva = {
            width: imageObj.width,
            height: imageObj.height,
          }
          vm.defaultSize = {
            width: imageObj.width,
            height: imageObj.height,
          }
          vm.mapImage = imageObj
          vm.stageScale = 1
        } catch (err) {
          console.log(err)
        }
      }
    },

    handleZoom(direction) {
      const scaleBy = 1.1
      const stageNode = this.$refs.stage.getNode()
      const oldScale = stageNode.scaleX()

      // how to scale? Zoom in? Or zoom out?
      let newScale = 1
      switch (direction) {
        case 1:
          newScale = oldScale * scaleBy
          break
        case -1:
          newScale = oldScale / scaleBy
          break
        case 0:
          newScale = 1
          break
        default:
          break
      }

      this.stageScale = newScale
      this.configKonva = {
        width: this.defaultSize.width * newScale,
        height: this.defaultSize.height * newScale,
      }
    },
    // Stage Events Listeners.
    handleStageMouseDown(e) {
      if (this.isDrawing && this.type === 'custom') return

      switch (this.mode) {
        case 'add':
          this.startAdd()
          break
        case 'edit':
          this.startEditCargo(e)
          break
        default:
          break
      }
    },
    handleStageMouseMove() {
      if (this.mode === 'add' && this.isDrawing && this.type !== 'custom') {
        this.adding()
      }
    },
    async handleStageMouseup() {
      if (this.mode === 'add' && this.type !== 'custom') {
        await this.stopAdd()
      }
    },
    handleStageClick(e) {
      this.endEditTitle()
      switch (this.mode) {
        case 'add':
          this.addCustomShape()
          break
        case 'delete':
          this.deleteCargo(e)
          break
        case 'select':
          this.selectCargo(e)
          break
        case 'selectBox':
          this.selectBox(e)
          break
        case '':
          this.showCargoDetail(e)
          break
        default:
          break
      }
    },
    handleStageDoubleClick(e) {
      const target = e.target
      this.endEditTitle()

      if (target.className === 'Text') {
        this.startEditTitle(target)
      }
    },
    handleCustomShapeDragMove(e) {
      this.updateAnchors(e)
    },
    startAdd() {
      if (this.type === 'custom') return

      if (!this.deliveryOrder) {
        showMessageBox(this, 'Nie wybrano zlecenie', 28, true)
        return
      }
      this.isDrawing = true
      const pos = this.$refs.stage.getNode().getPointerPosition()
      const id = this.getNextId()
      const name = 'cargo_' + id
      const text = this.getNewText(id)
      pos.x /= this.stageScale
      pos.y /= this.stageScale

      const shape = {
        id,
        name,
        startPointX: pos.x,
        startPointY: pos.y,
        scaleX: 1,
        scaleY: 1,
        rotation: 0,
        fill: this.color,
      }
      const title = {
        text: text,
        name: name + '_title',
        fill: '#000000',
        fontSize: 18,
        x: pos.x,
      }

      const attributes = {
        shipRegistrationId: this.shipRegistration?.id,
        deliveryOrderId: this.deliveryOrder?.id,
        productId: this.product?.id,
        deliveryOrdersArray: [],
        boxId: null,
        isModified: true,
      }
      if (this.deliveryOrder) {
        attributes.deliveryOrdersArray.push(this.deliveryOrder.id)
      }

      switch (this.type) {
        case 'rect':
          shape.width = 0
          shape.height = 0
          break
        case 'circle':
          shape.radius = 0
          shape.x = 0
          shape.y = 0
          break
        default:
          break
      }

      this.formItem = {
        type: this.type,
        shape,
        title,
        attributes,
      }
    },
    adding() {
      const point = this.$refs.stage.getNode().getPointerPosition()
      point.x /= this.stageScale
      point.y /= this.stageScale

      // handle rectangle part
      const curItem = this.formItem
      const width = point.x - curItem.shape.startPointX
      const height = point.y - curItem.shape.startPointY

      switch (this.type) {
        case 'rect':
          curItem.shape.width = Math.abs(width)
          curItem.shape.height = Math.abs(height)
          curItem.shape.x = Math.min(curItem.shape.startPointX, curItem.shape.startPointX + width)
          curItem.shape.y = Math.min(curItem.shape.startPointY, curItem.shape.startPointY + height)
          break
        case 'circle':
          curItem.shape.radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2))
          curItem.shape.x = curItem.shape.startPointX
          curItem.shape.y = curItem.shape.startPointY
          break
        default:
          break
      }

      const titleCoordinates = this.getTitleCoordinates(curItem)
      curItem.title.x = titleCoordinates.x
      curItem.title.y = titleCoordinates.y
    },
    async stopAdd() {
      this.isDrawing = false
      const shape = this.formItem.shape
      delete shape.startPointX
      delete shape.startPointY
      this.data.push({ ...this.formItem, shape })

      await this.saveItem(this.formItem)

      this.formItem = {
        type: '',
        shape: {},
        title: {},
      }
      this.mode = ''
    },
    // Add Custom Shape.
    addCustomShape() {
      if (!this.deliveryOrder) {
        showMessageBox(this, 'Nie wybrano zlecenie', 28, true)
        return
      }
      if (this.isDrawing || this.type !== 'custom') return

      const stageNode = this.$refs.stage.getNode()
      const point = stageNode.getPointerPosition()
      point.x /= this.stageScale
      point.y /= this.stageScale

      // Start Add.
      if (this.formItem.type !== 'custom') {
        const id = this.getNextId()
        const name = 'cargo_' + id
        const text = this.getNewText(id)

        this.formItem = {
          type: 'custom',
          shape: {
            id,
            name,
            stroke: this.color,
            strokeWidth: 5,
            points: [point.x, point.y],
            xPoints: [point.x],
            yPoints: [point.y],
          },
          title: {
            text: text,
            name: name + '_title',
            fill: '#000000',
            fontSize: 18,
            x: point.x,
            y: point.y,
          },

          attributes: {
            shipRegistrationId: this.shipRegistration?.id,
            deliveryOrderId: this.deliveryOrder?.id,
            productId: this.product?.id,
            boxId: null,
            isModified: true,
          },
        }
        this.addNewAnchor(point, null, true)
        return
      }

      // Add more anchors
      const formItem = { ...this.formItem }
      const curShape = formItem.shape
      const curNode = stageNode.findOne('.' + curShape.name)

      curShape.points.push(point.x)
      curShape.points.push(point.y)
      curShape.xPoints.push(point.x)
      curShape.yPoints.push(point.y)

      const titleCoordinates = this.getTitleCoordinates(formItem)
      formItem.title.x = titleCoordinates.x
      formItem.title.y = titleCoordinates.y

      this.formItem = formItem
      this.addNewAnchor(point, curNode)
    },
    addNewAnchor(point, poly, isClosePoint) {
      const anchor = new Konva.Rect({
        x: point.x - 4,
        y: point.y - 4,
        width: 8,
        height: 8,
        fill: 'white',
        stroke: 'black',
        draggable: true,
        name: 'anchor',
      })
      this.anchors.push(anchor)
      this.$refs.formLayer.getNode().add(anchor)

      if (isClosePoint) {
        anchor.on('click', () => this.endAddCustomShape())
      } else {
        anchor.on('dragmove', () => this.updatePoly(poly))
        anchor.on('dragend', () => this.endDragAnchor())
      }
    },
    async endAddCustomShape() {
      const formItem = { ...this.formItem }
      const curShape = formItem.shape
      curShape.closed = true
      curShape.strokeWidth = 0
      curShape.fill = curShape.stroke
      curShape.width = Math.max(...curShape.xPoints) - Math.min(...curShape.xPoints)
      curShape.height = Math.max(...curShape.yPoints) - Math.min(...curShape.yPoints)

      this.data.push(this.formItem)
      await this.saveItem(this.formItem)

      this.formItem = {
        type: '',
        shape: {},
        title: {},
      }
      this.polyTransformerDetach()
      this.mode = ''
    },
    polyTransformerDetach() {
      if (this.anchors.length < 1) return

      for (let i = 0; i < this.anchors.length; i++) {
        this.anchors[i].destroy()
      }
      this.anchors = []
      this.$refs.formLayer.getNode().batchDraw()
    },

    // Edit a cargo.
    startEditCargo(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedCargoName = ''
        this.updateTransformer('custom')
        return
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer = e.target.getParent().className === 'Transformer'
      if (clickedOnTransformer) {
        return
      }

      // find clicked rect by its name
      const name = e.target.name()
      if (name === 'anchor') return

      const item = this.data.find((r) => name === r.shape.name || name === r.title.name)

      if (item) {
        this.selectedCargoName = item.shape.name
        this.color = item.shape.fill
      } else {
        this.selectedCargoName = ''
      }

      this.polyTransformer(item?.type === 'custom' ? item.shape.points : [])
      this.updateTransformer(item?.type)
    },
    handleTransformEnd(e) {
      // Resize cargos
      // shape is transformed, let us save new attrs back to the node
      // find an element in our state
      const data = _.cloneDeep([...this.data])
      const item = data.find((r) => r.shape.name === this.selectedCargoName)
      // update the state
      item.shape.x = e.target.x()
      item.shape.y = e.target.y()
      item.shape.rotation = e.target.rotation()
      item.shape.scaleX = e.target.scaleX()
      item.shape.scaleY = e.target.scaleY()

      const titleCoordinates = this.getTitleCoordinates(item)
      item.title.x = titleCoordinates.x
      item.title.y = titleCoordinates.y

      item.attributes.isModified = true

      this.data = data
      this.saveItem(item)
    },
    updateTransformer(type) {
      // here we need to manually attach or detach the Transformer node
      const transformerNode = this.$refs.transformer.getNode()

      if (type === 'custom') {
        return transformerNode.nodes([])
      }

      const stage = transformerNode.getStage()

      const selectedNode = stage.findOne('.' + this.selectedCargoName)
      // do nothing if the selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode])
      } else {
        // remove transformer
        transformerNode.nodes([])
      }
    },
    polyTransformer(points) {
      this.polyTransformerDetach()

      const stage = this.$refs.stage.getNode()
      const selectedPoly = stage.findOne('.' + this.selectedCargoName)

      for (let i = 0; i <= points.length - 2; i = i + 2) {
        this.addNewAnchor({ x: points[i], y: points[i + 1] }, selectedPoly)
      }
    },
    updatePoly(poly) {
      let points = []
      for (let i = 0; i < this.anchors.length; i++) {
        points = [...points, this.anchors[i].x() + 4, this.anchors[i].y() + 4]
        poly.points(points)
        this.$refs.formLayer.getNode().batchDraw()
      }
    },
    async updateAnchors(e) {
      const selectedItem = this.data.find((item) => item.shape.name === this.selectedCargoName)

      const offsetX = e.target.x()
      const offsetY = e.target.y()

      let j = 0
      for (let i = 0; i < this.anchors.length; i++) {
        const newX = selectedItem.shape.points[j] + offsetX
        const newY = selectedItem.shape.points[j + 1] + offsetY

        this.anchors[i].x(newX - 4)
        this.anchors[i].y(newY - 4)
        j = j + 2
      }
    },
    endDragAnchor() {
      const stage = this.$refs.stage.getNode()
      const curNode = stage.findOne('.' + this.selectedCargoName)
      const curItem = this.data.find((item) => item.shape.name === this.selectedCargoName)
      const points = curNode.points()
      const xPoints = []
      const yPoints = []
      points.map((point, i) => {
        if (i % 2 === 0) {
          xPoints.push(point)
        } else {
          yPoints.push(point)
        }
      })

      curItem.shape.points = points
      curItem.shape.xPoints = xPoints
      curItem.shape.yPoints = yPoints

      const titleCoordinates = this.getTitleCoordinates(curItem)
      curItem.title.x = titleCoordinates.x
      curItem.title.y = titleCoordinates.y
    },
    async stopDrag(e) {
      if (!this.selectedCargoName) return

      const data = _.cloneDeep([...this.data])
      const index = data.findIndex((r) => r.shape.name === this.selectedCargoName)
      const cargo = data[index]

      if (cargo.type === 'custom') {
        let points = []
        const xPoints = []
        const yPoints = []
        this.anchors.map((anchor) => {
          points = [...points, anchor.x() + 4, anchor.y() + 4]
          xPoints.push(anchor.x() + 4)
          yPoints.push(anchor.y() + 4)
        })
        cargo.shape.points = points
        cargo.shape.xPoints = xPoints
        cargo.shape.yPoints = yPoints
      } else {
        const transformerNode = this.$refs.transformer.getNode()
        const newPos = transformerNode.node().getAbsolutePosition()
        newPos.x /= this.stageScale
        newPos.y /= this.stageScale
        cargo.shape.x = newPos.x
        cargo.shape.y = newPos.y
        transformerNode.node().x(newPos.x)
        transformerNode.node().y(newPos.y)
      }
      const titleCoordinates = this.getTitleCoordinates(cargo)
      cargo.title.x = titleCoordinates.x
      cargo.title.y = titleCoordinates.y
      cargo.attributes.isModified = true

      this.data = data
      this.saveItem(cargo)

      e.target.x(0)
      e.target.y(0)
    },

    showCargoDetail(e) {
      const name = e.target.name()

      if (!name) {
        this.selectedCargoTitle = ''
        return
      }

      const cargo = this.data.find((item) => item.title.name === name || item.shape.name === name)
      this.selectedCargoTitle = cargo.title.text
    },

    deleteCargo(e) {
      const name = e.target.name()
      const index = this.data.findIndex((r) => name === r.shape.name || name === r.title.name)
      if (index < 0) return

      this.data.splice(index, 1)
    },

    selectCargo(e) {
      if (!this.deliveryOrder) {
        showMessageBox(this, 'Nie wybrano zlecenie', 28, true)
        return
      }

      const name = e.target.name()
      const item = this.data.find((r) => name === r.shape.name || name === r.title.name)

      if (item) {
        if (!Object.hasOwn(item.attributes, 'deliveryOrdersArray')) {
          item.attributes.deliveryOrdersArray = []
        }
        const findIndex = item.attributes.deliveryOrdersArray.indexOf(this.deliveryOrder.id)
        if (findIndex === -1) {
          item.attributes.deliveryOrdersArray.push(this.deliveryOrder.id)
          item.attributes.isModified = true
          // item.shape.fill = this.color
        }
        item.attributes.deliveryOrderId = this.deliveryOrder.id
        item.attributes.productId = this.product?.id
        this.saveItem(item)
        this.mode = ''
      }

      this.polyTransformer(item?.type === 'custom' ? item.shape.points : [])
      this.updateTransformer(item?.type)
    },

    selectBox(e) {
      const name = e.target.name()
      const item = this.data.find((r) => name === r.shape.name || name === r.title.name)

      if (item) {
        console.log('selectBox = ', item.attributes)
        this.selectedBox = item.attributes.boxId
        // this.$router.push({
        //   path: this.parentViewId,
        //   params: {
        //     selectedCell: this.selectedCell,
        //     boxId: item.attributes.boxId,
        //   },
        // })
      }
    },

    getNextId() {
      const length = this.data.length
      if (length < 1) {
        return 1
      }

      // if (this.data[length - 1].shape.id) {
      //   return ++this.data[length - 1].shape.id
      // } else {
      //   return 1
      // }

      let maxId = 0
      for (const item of this.data) {
        const shapeId = item.shape.id
        if (maxId < shapeId) {
          maxId = shapeId
        }
      }

      return ++maxId
    },

    getNewText(id) {
      let countShapeOfDeliveryOrder = 0
      for (const item of this.data) {
        if ('attributes' in item) {
          const findIndex = item.attributes.deliveryOrdersArray.indexOf(this.deliveryOrder.id)
          if (findIndex === -1) {
            continue
          }
          // if (item.attributes.deliveryOrderId === this.deliveryOrder.id) {
          countShapeOfDeliveryOrder += 1
          // }
        }
      }
      let returnValue = 'Cargo ' + id
      if (this.deliveryOrder) {
        returnValue = this.deliveryOrder.numberStr + '-' + Number(countShapeOfDeliveryOrder + 1)
      }

      return returnValue
    },

    getTitleCoordinates(item) {
      const stage = this.$refs.stage.getNode()
      const titleNode = stage.findOne('.' + item.title.name)

      let x = 0
      let y = 0
      if (item.type === 'circle') {
        x = item.shape.x - titleNode.width() / 2
        y = item.shape.y - titleNode.height() / 2
        return { x, y }
      }

      if (item.type === 'custom') {
        const minX = Math.min(...item.shape.xPoints)
        const minY = Math.min(...item.shape.yPoints)
        const offsetX = (Math.max(...item.shape.xPoints) - minX) / 2
        const offsetY = (Math.max(...item.shape.yPoints) - minY) / 2

        x = minX + offsetX - titleNode.width() / 2
        y = minY + offsetY - titleNode.height() / 2
        return { x, y }
      }

      // If the shape has rotation, adjust the center position
      const angleRad = (item.shape.rotation * Math.PI) / 180 // Convert degrees to radians
      const cosAngle = Math.cos(angleRad)
      const sinAngle = Math.sin(angleRad)

      // Calculate center position after rotation
      const offsetX = (item.shape.width * item.shape.scaleX) / 2
      const offsetY = (item.shape.height * item.shape.scaleY) / 2

      x = item.shape.x + (offsetX * cosAngle - offsetY * sinAngle)
      y = item.shape.y + (offsetX * sinAngle + offsetY * cosAngle)
      x -= titleNode.width() / 2
      y -= titleNode.height() / 2

      return { x, y }
    },

    startEditTitle(titleNode) {
      this.selectedTitleNode = titleNode
      titleNode.hide()
      const position = titleNode.absolutePosition()
      const titleInputRef = this.$refs.titleInput

      this.titleValue = titleNode.text()
      titleInputRef.style.left = position.x + 'px'
      titleInputRef.style.top = position.y + 'px'
      titleInputRef.style.fontFamily = titleNode.fontFamily()
      titleInputRef.style.lineHeight = titleNode.lineHeight()
      titleInputRef.style.display = 'block'

      titleInputRef.focus()
    },
    endEditTitle() {
      const titleInputRef = this.$refs.titleInput
      titleInputRef.style.display = 'none'

      if (this.selectedTitleNode) {
        const item = this.data.find((item) => item.title.name === this.selectedTitleNode.name())
        this.selectedTitleNode.text(this.titleValue)

        const titleCoordinates = this.getTitleCoordinates(item)
        item.title.text = this.titleValue
        item.title.x = titleCoordinates.x
        item.title.y = titleCoordinates.y

        item.attributes.isModified = true
        this.saveItem(item)

        this.selectedTitleNode.show()
        this.selectedTitleNode = null
      }
    },

    async setColorProducts() {
      for (const row of this.deliveryOrdersArray) {
        if (!row.product) {
          continue
        }
        const colorProduct = await this.getColorProduct(row.product)
        if (colorProduct) {
          // row._cellVariants = { product: colorProduct }
          row.product.color = colorProduct
        }
      }
    },
    convertColorProduct(strColor) {
      let jsonColor = ''
      if (strColor) {
        try {
          jsonColor = JSON.parse(strColor)
        } catch (error) {
          jsonColor = '{"r": 0, "g": 0, "b": 0}'
        }
      }
      if (jsonColor) {
        return commoUseRgbToHex(jsonColor.r, jsonColor.g, jsonColor.b)
      } else {
        return this.defaultColor
      }
    },
    openContextMenu(event, item) {
      this.setCurrentViewProperty({ selectedBoxFromList: item.attributes.boxId })
      this.load()

      const nativeEvent = event.evt
      nativeEvent.preventDefault()

      const mouseX = nativeEvent.pageX
      const mouseY = nativeEvent.pageY

      const pageHeight = window.innerHeight
      const pageWidth = window.innerWidth

      const menuWidth = 300
      const menuHeight = 300

      this.contextMenu.left = Math.min(mouseX, pageWidth - menuWidth)
      this.contextMenu.top = Math.min(mouseY, pageHeight - menuHeight)

      this.contextMenu.visible = true
    },

    closeContextMenu() {
      this.contextMenu.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 1030;
  position: fixed;
  list-style-type: none;
  padding: 5px 0;
  width: 300px;
  border-radius: 2px;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
}
.toolbar {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.container {
  background-color: white;
  max-width: calc(100vw - 64px);
  max-height: calc(100vh - 140px);
  overflow: auto;
}

.title-input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  background: none;
  border: none;
  outline: none;
  resize: none;
  z-index: 99999;
}
</style>
